import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(root)": [56,[10]],
		"/(root)/activities/[id]": [57,[10]],
		"/(admin)/admin/check-in/[eventName]/[year]": [~46,[9]],
		"/(root)/blog": [58,[10,11]],
		"/(root)/blog/[article]": [59,[10,11]],
		"/(root)/call-for-speakers": [60,[10]],
		"/(root)/call-for-speakers/[event]/[year]": [61,[10]],
		"/(redirects)/camp": [48],
		"/(redirects)/cfp": [49],
		"/(redirects)/family": [50],
		"/(root)/login-redirect": [62,[10]],
		"/(redirects)/membership": [52],
		"/(root)/members": [63,[10,12]],
		"/(root)/members/[member]": [64,[10,12,13]],
		"/(admin my)/my": [23,[2]],
		"/(admin my)/my/favorites": [24,[2]],
		"/(admin my)/my/member-sharing": [25,[2]],
		"/(admin my)/my/member-sharing/[eventId]": [26,[3]],
		"/(admin my)/my/network": [27,[2,4]],
		"/(admin my)/my/network/members": [~28,[2,4]],
		"/(admin my)/my/network/sponsors": [29,[2,4]],
		"/(admin my)/my/notification-preferences": [30,[2]],
		"/(admin my)/my/profiles": [31,[2,5]],
		"/(admin my)/my/profiles/emergency-contact": [~32,[2,5]],
		"/(admin my)/my/profiles/primary": [~33,[2,5]],
		"/(admin my)/my/profiles/public": [~34,[2,5]],
		"/(admin my)/my/profiles/shared": [~35,[2,5]],
		"/(admin my)/my/profiles/slack": [36,[2,5]],
		"/(admin my)/my/settings": [37,[2,6]],
		"/(admin my)/my/settings/badges": [38,[2,6]],
		"/(admin my)/my/settings/membership": [39,[2,6]],
		"/(admin my)/my/settings/order-history": [~40,[2,6]],
		"/(admin my)/my/settings/tickets": [41,[2,6]],
		"/(admin my)/my/submissions": [42,[2]],
		"/(redirects)/m/[member]": [51],
		"/(root)/newsletter": [65,[10]],
		"/(root)/newsletter/verify-your-humanity": [66,[10]],
		"/(root)/newsletter/what-now": [67,[10]],
		"/(root)/orders/canceled": [70,[10,14]],
		"/(root)/orders/(orders)/claim": [68,[10,14,15]],
		"/(root)/orders/success": [71,[10,14,16]],
		"/(root)/orders/success/claim-ticket": [72,[10,14,16]],
		"/(root)/orders/success/expo-hall-ticket": [73,[10,14,16]],
		"/(root)/orders/(orders)/summary": [69,[10,14,15]],
		"/(redirects)/partners/[partner]": [53],
		"/(event support)/signs": [47],
		"/(root)/signup": [74,[10]],
		"/(root)/speakers/accept": [75,[10,17]],
		"/(root)/speakers/declined": [76,[10,17]],
		"/(admin sponsor)/sponsor-admin/leads": [43,[7]],
		"/(admin sponsor)/sponsor-admin/leads/[eventId]": [44,[7]],
		"/(admin sponsor)/sponsor-admin/my-network": [~45,[8]],
		"/(root)/sponsorships/apply": [83,[10,19]],
		"/(root)/sponsorships/prospectus": [84,[10,19]],
		"/(root)/sponsors": [77,[10,18]],
		"/(root)/sponsors/job-board": [81,[10,18]],
		"/(root)/sponsors/past": [82,[10,18]],
		"/(root)/sponsors/[partner]": [78,[10,18]],
		"/(root)/sponsors/[partner]/jobs/[job]": [79,[10,18]],
		"/(root)/sponsors/[state]/[year]": [80,[10,18]],
		"/(root)/support": [85,[10,20]],
		"/(root)/support/anti-harassment": [86,[10,20]],
		"/(root)/support/code-of-conduct": [87,[10,20]],
		"/(root)/support/commitment-to-diversity": [88,[10,20]],
		"/(root)/support/contact-us": [89,[10,20]],
		"/(root)/support/convince-your-boss": [90,[10,20]],
		"/(root)/support/copyright": [91,[10,20]],
		"/(root)/support/covid-policies": [92,[10,20]],
		"/(root)/support/creating-an-activity": [93,[10,20]],
		"/(root)/support/faq": [94,[10,20]],
		"/(root)/support/joining-an-activity": [95,[10,20]],
		"/(root)/support/my-favorites-icalendar": [96,[10,20]],
		"/(root)/support/privacy-policy": [97,[10,20]],
		"/(root)/support/sponsors/expo-hall": [98,[10,20]],
		"/(root)/support/sponsors/forms": [99,[10,20]],
		"/(root)/support/staying-up-to-date": [100,[10,20]],
		"/(root)/support/terms-of-use": [101,[10,20]],
		"/(root)/support/that-field-guide": [102,[10,20]],
		"/(root)/support/transfer-a-ticket": [103,[10,20]],
		"/(root)/support/travel": [104,[10,20]],
		"/(root)/support/travel/tx": [105,[10,20]],
		"/(root)/support/travel/wi": [106,[10,20]],
		"/(root)/support/what-is-an-activity": [107,[10,20]],
		"/(redirects)/tx": [54],
		"/(that conferences)/tx/[year]": [110,[22]],
		"/(that conferences)/tx/[year]/schedule": [111,[22]],
		"/(that conferences)/tx/[year]/speakers": [112,[22]],
		"/(that conferences)/tx/[year]/sponsors": [113,[22]],
		"/(that conferences)/tx/[year]/tickets": [114,[22]],
		"/(that conferences)/tx/[year]/travel": [115,[22]],
		"/(root)/verify-account": [108,[10]],
		"/(redirects)/wi": [55],
		"/(that conferences)/wi/[year]": [116,[22]],
		"/(that conferences)/wi/[year]/schedule": [117,[22]],
		"/(that conferences)/wi/[year]/speakers": [118,[22]],
		"/(that conferences)/wi/[year]/sponsors": [119,[22]],
		"/(that conferences)/wi/[year]/tickets": [120,[22]],
		"/(that conferences)/wi/[year]/travel": [121,[22]],
		"/(that conference online)/[event]/[date]": [109,[21]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';